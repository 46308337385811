.logContainer {
    text-align: center;
    a {
    color: white;
    text-decoration: none;
    }
}

.log-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.log-card {
    background: #272727;
    color: #fff;
    width: 25em;
    margin: 3em 1em;
    border-radius: 1em;
    padding-bottom: 4em;
    position: relative;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.remove-log-btn {
    position: absolute;
    cursor: pointer;
    color: tomato;
    font-size: 1.5em;
    right: 1em;
    top: .8em;
}

.edit-log-btn {
    position: absolute;
    cursor: pointer;
    color: tomato;
    font-size: 1.5em;
    right: 2.5em;
    top: .8em;
}

.log-card-header {
    background: #222;
    padding: 2em;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

.cal-emoji {
    font-size: 3em;
}
