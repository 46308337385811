.feedback-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    p {
    margin: .5em;
    }
    span {
    color: orange;
    }
    p:nth-of-type(3) {
    margin-bottom: 2em;
    }
    form {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    input {
    display: block;
    margin-bottom: 2em;
    padding: .3em;
    border-radius: .2em;
    border: none;
    font-family: monospace;
    width: 20em;
    }
    label {
    margin-bottom: .5em;
    }
    button {
    margin-top: 1.5em;
    }
    #message {
    resize: none;
    padding: .3em;
    border-radius: .2em;
    border: none;
    font-family: monospace;
    width: 20em;
    }
    .checkmark {
    font-size: 50px;
    }
    .disabledBtn {
        width: 6em;
        font-size: 1.3em;
        border-radius: 2em;
        padding: .6em;
        border: none;
        color:white;
        // margin: 0;
        outline: none;
        // background-color: transparent;
        cursor: not-allowed;
    }
    .recaptcha {
        padding: 2em;
    }
}