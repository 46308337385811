.home-container {
    padding-bottom: 4em;
    background-color: #333;
    &-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-items: center;
        align-items: center;
        margin-top: 5em;
        @media (max-width: 700px) {
            margin-top: 3em;
        }
    }
    h1 {
        color: orange;
        font-size: 3em;
        @media (max-width: 700px) {
            font-size: clamp(2em, 7vw, 5em);
        }
    }
    a {
        text-decoration: none;
        color: #333;
    }
    p {
        width: 30em;
        padding-bottom: .8em;
        font-size: 1.1em;
        @media (max-width: 700px) {
            width: 20em;
            // font-size: 1em;
        }
    }

    div:nth-of-type(1) {
        div:nth-of-type(1) {
            padding: auto 1em;
            width: 650px;
            @media (max-width: 700px) {
                width: 60%;
            }
        }
        div:nth-of-type(2) {
            padding: auto 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        @media (max-width: 700px) {
            flex-direction: column;
        }
    }

    div:nth-of-type(2) {
        h2 {
            font-size: 2.8em;
            @media (max-width: 700px) {
                font-size: 2.2em;
                text-align: center;
            }
        }
        ul {
            font-size: 1.2em;
            list-style: none;
            li {
                padding: .8em;
            }
            span {
                font-weight: 800;
                color: orange;
            }
            @media (max-width: 700px) {
                padding-left: 0;
            }
        }
        div:nth-of-type(1){
            margin: 3em;
        }
        div:nth-of-type(2){
            width: 400px;
            padding: auto 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
            // text-align: center;
            @media (max-width: 700px) {
                width: 60%;
            }    
        }
        @media (max-width: 1190px) {
            flex-direction: column-reverse;
        }
    }

    div:nth-of-type(3) {
        display: flex;
        justify-content: space-around;
        div:nth-of-type(1) {
            width: 600px;
            @media (max-width: 700px) {
                width: 60%;
            } 
        }
        div:nth-of-type(2) {
            width: 545px;
            @media (max-width: 1150px) {
                margin-top: 5em;
            }
            @media (max-width: 700px) {
                width: 60%;
            } 
        }
    }

}

.button {
    margin: 2em;
    background-color: orange;
    border: none;
    border-radius: 2em;
    padding: 1em;
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

.home-subtitle {
    color: white;
    margin-bottom: 0;
    font-weight: 100;
}
