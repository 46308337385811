header {
    background-color: #222;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3em;
    padding-bottom: 5em;

    padding-left: 1em;
    padding-right: 1em;
    height: 7.7em;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    nav {
        // display: flex;
        // justify-content: space-around;
        // max-width: 20em;
        // min-width: 8em;
        @media (max-width: 500px) {
            display: none;
        } 
    }
    // .burger-button {
    //     position: fixed;
    //     width: 36px;
    //     height: 30px;
    //     // top: 2.1em;
    //     right: em;
    // }
    a[tooltip] {
        position: relative;
    }
    a[tooltip]::before {
        content: attr(tooltip);
        display: block;
        position: absolute;
        background: #707070;
        color: white;
        padding: 1em 2em;
        font-size: .8em;
        border-radius: .5em;
        top: 0;
        left: 0;
        white-space: nowrap;
        transform: scale(0);
        transition: transform ease-out 150ms, top ease-out 150ms;
        transition-delay: 100ms;
        @media (max-width: 700px) {
            display: none;
        }
    }
    a[tooltip]:hover::before {
        transform: scale(1);
        top: 120%;
    }
}

.header-alert {
    position: fixed;
    background: #272727;
    display: flex;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 1px solid white;
    border-radius: 1em;
    a {
        color: white;
        text-decoration: none;
        padding: 1em;
    }
}

// .header-logo {
//     // position: fixed;
//     // right: 5%;
//     // left: 5%;
// }

.header-icon {
    margin: auto;
    position: fixed;
    right: 40%;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 10em;
    div {
        margin: .2em 0 .5em 0;
        padding: 0;
        position: fixed;
    }
}

.header-logo {
    top: 10px;
}