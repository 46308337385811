footer {
    background-color: #101110;
    color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    /* height: 7em; */
    padding-top: 1.5em ;
    padding-bottom: 1.5em ;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
    a[tooltip] {
        position: relative;
    }
    a[tooltip]::before {
        content: attr(tooltip);
        display: block;
        position: absolute;
        background: #707070;
        color: white;
        padding: 1em 2em;
        font-size: .8em;
        border-radius: .5em;
        bottom: 0;
        transform: scale(0);
        transition: transform ease-out 150ms, bottom ease-out 150ms;
        transition-delay: 100ms;
        @media (max-width: 700px) {
            display: none;
        }
    }
    a[tooltip]:hover::before {
        transform: scale(1);
        bottom: 120%;
    }
}

.footer-icon {
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
}