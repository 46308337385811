.about-container {
    display: grid;
    place-items: center;
    p {
        width: 28em;
        text-align: center;
        
        @media (max-width: 700px) {
            width: 80vw;
        } 
    }
    a {
        color: white;
    }
    
}