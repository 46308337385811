.timerBtn {
    width: 6em;
    font-size: 1.3em;
    border-radius: 2em;
    padding: .6em;
    border: none;
    color:white;
    margin: .5em;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color .5s;
    &:hover {
        color: #333;
    }
    &:active {
        color: white;
    }
}

.timerDisplay {
    font-weight: 100;
}

.startBtn {
    border: solid 1px #5AC18E;
    &:hover {
        background-color:#5AC18E;
    }
}

.pauseBtn {
    border: solid 1px dodgerblue;
    &:hover {
        background: dodgerblue;
    }
}

.resumeBtn {
    border: solid 1px #5AC18E;
    &:hover {
    background-color:#5AC18E;
    color: #333;
    }
}

.stopBtn {
    border: solid 1px tomato;
    &:hover {
    background-color: tomato;
    }
}
