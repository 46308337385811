.stats-container {
    // display: grid;
    // place-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#streaks {
    div {
        margin: 1em;
        text-align: center;
    }
}