* {
  box-sizing: border-box;
  ::selection { background-color: orange;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #333;
  color: white;
  padding-top: 8em;
  padding-bottom: 8em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sr-only {
  position: absolute;
  right: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input {
  font-family: monospace;
}

.modalBtn {
  padding: .5em 1em;
  border-radius: 3em;
  border: none;
  margin: 1em;
  cursor: pointer;
  font-size: 1em;
}

.submit {
  background: #5AC18E;
  color: white;
}

.cancel {
  background: tomato;
  color: white;
}

.skip {
  background: dodgerblue;
  color: white;
}