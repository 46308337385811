.rep-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.rep-table {
    border-collapse: collapse;
    margin: 2em;
    table-layout: fixed;
    td, th {
        border: 1px solid white;
        padding: 1em;
        width: 10em;
    }
}

.rep-table td, .rep-table th {

}

.rep-search-field {
    width: 30em;
    border: none;
    padding: .5em;
}

.add-rep {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-rep input {
    display: block;
    border: none;
    margin-bottom: .5em;
}

.remove-edit-rep {
    display: flex;
    justify-content: flex-end;
}

.rep-icon {
    margin: .3em;
    cursor: pointer;
    position: relative;
    transform: translate(.5em, -.6em);
}

.rep-notes {
    transform: translateY(-1em);
}