.Drone {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 16em; */
    text-align: center;
}

.Drone input, .Drone select {
    /* margin: 1em; */
    font-size: 1.2em;
    text-align: center;
    width: 10em;
    border: none;
    /* margin-top: 1em; */
}

.Drone label {
    margin-top: 1em;
    margin-bottom: 0;
}

#droneRoot {
    width: 3em;
}

.Drone button {
    padding: .8em;
    margin: .5em;
    border-radius: 2em;
    outline: 2em;
    font-size: 18px;
    width: 100px;
    cursor: pointer;
}

.start-drone-btn:hover {
    background: #fff;
    color: #333;
}

.stop-drone-btn {
    background: #5AC18E;
    border: #5AC18E solid 1px;
    color: #333;
}

.start-drone-btn {
    background: transparent;
    color: #fff;
    border: #fff 1px solid;
}