.fork-container {
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    label {
        margin-bottom: 1em;
    }
    select {
        margin-bottom: 1em;
        font-family: monospace;
        padding: .5em;
        font-size: 1.2em;
    }
}