.userContainer {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    margin-right: 3em;
    text-align: center;
    h1 {
        margin-bottom: 0;
    }
    p {
        margin-bottom: 4em;
    }
    a {
        text-decoration: none;
        color: white;
        margin: 0 2em 2em 2em;

    }
    .card {
        background: #272727;
        padding: 2em;
        border-radius: 2em;
        text-align: center;
        width: 15em;
        height: 15em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

        h2 {
            padding-top: 1em;
        }
    }
}

.userDashboard {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
