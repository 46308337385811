.formContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 1em;
    padding-top: 1.5em;
}

.submitBtn {
    background: orange;
    color: #333;
    &:hover {
        color: white;
    }
}

.cancelBtn {
    border: solid 1px white;
    &:hover {
        background: white
    }
    &:active {
        background-color: tomato;
        border: solid 1px tomato;
    }
}

.newSessionContainer {
    padding: 3em;
    width: 25em;
    background-color: #272727;
    margin: 0 2em 2em 2em;
    border-radius: 2em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    position: relative;
    h1 {
        margin-bottom: 0;
        font-weight: 100;
    }
    h4 {
        font-weight: 300;
        margin-bottom: 0;
    }
    h4, .label-margin {
        margin-bottom: .3em;
    }
}

.timerDisplay {
    font-size: 4em;
}

.prForm {
    input, textarea {
        font-size: 1.2em;
        margin: .2em;
        padding: .3em;
        border-radius: .2em;
        border: none;
        font-family: monospace;
    }
    .timerBtn {
        margin-top: 1em;
    }
    textarea {
        resize: none;
        width: 80%;
    }
}

.pr-time-input input {
    width: 3em;
    z-index: 99;
}

.start-time-input input {
    width: 13em;
} 

.modal {
    background-color: #333;
    width: 22em;
    height: 25em;
    margin: auto;
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    padding: 2em;
    border-radius: 2em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.322);
    padding-bottom: 6em;
    input[type="number"] {
    font-family: monospace;
    border: none;
    font-size: 20px;
    text-align: center;
    }
    .timerBtn {
        margin: 1em;
    }
    h2 {
        font-weight: 100;
    }
    ul {
        list-style: none;
    }
}

.modal-container {
    background: rgba(0, 0, 0, 0.4);
    width: 100vw;
    min-height: 100vh;
    margin: auto;
    position: fixed;
    top: 0; left: 0; bottom: 0; right: 0;
    input {
        font-size: 1.2em;
        margin: .2em;
        padding: .2em;
        border-radius: .2em;
    }
    textarea {
        resize: none;
        width: 80%;
    }
}

.practice-card-icon {
    position: absolute;
    right: 1.5em;
    top: 1.5em;
}

.confirm-log-list {
    li {
        margin: .5em;
    }
}