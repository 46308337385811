.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.burger-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #222;
    padding: 2.5em 1.5em;
    font-size: 1.15em;
    position: fixed;
    top: -200em;
    right: 0;
    width: 16em;
    transition: top 1s ease-out;
    // z-index: 999;
    a {
    display: block;
    padding: 1em;
    text-decoration: none;
    color: white;
    padding-left: .6em;
    }
}

.burger-menu-open {
    top: 5em;
    right: 0;
    transition: top 300ms ease;
}

.menu-item {
    transition: color .3s;
    &:hover {
        color: orange;
    }
}